// Here you can add other styles
.header-right{
  float:right;
}

.row-icon{
  float:left;
}

.geticonset{
  color: #2eb85c;
  display: inline-block;
  text-align: center;
  fill: currentColor;
  height: 20px;
}

.btn-primary {
    color: #fff;
    background-color: #007FB8;
    border-color: #007FB8;
}

.c-sidebar {
    /*color: #195A84;*/
    /*background: #fff;*/
    color:#fff;
    background: #007fb8;

}

.c-sidebar .c-sidebar-brand {
    /*color: #195A84;*/
    color:#fff;
    background: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #195A84;
  border-color: #195A84;
}

.text-muted {
    color: #195A84 !important;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #fff;
    background: transparent;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    color: #fff;
    background: rgba(255, 255, 255, 0.05);
}

.c-sidebar-nav-icon {
    flex: 0 0 56px;
    height: 1.09375rem;
    font-size: 1.09375rem;
    text-align: center;
    transition: 0.3s;
    fill: #fff;
}

.c-sidebar-nav-icon-white {
    flex: 0 0 56px;
    height: 1.09375rem;
    font-size: 1.09375rem;
    text-align: center;
    transition: 0.3s;
    fill: #195A84;
}

.wi-icon-white{
    color: #195A84;
}

.wi-icon-overload{
    color: #af0028;
}

.htext{
    color: #195A84;
    margin-top:-10px;
    margin-bottom: 20px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #195A84;
  border-color: #195A84;
}

.bg-gradient-primary {
    background: #fff !important;
    border-color: #195A84 !important;
}

.bg-gradient-info {
    background: #fff !important;
    border-color: #195A84 !important;
}

.bg-gradient-warning {
    background: #fff !important;
    border-color: #195A84 !important;
}

.bg-gradient-danger {
    background: #fff !important;
    border-color: #195A84 !important;
}

.text-value-lg {
    font-size: 1.3125rem;
    color: #195A84;
}

.text-white {
    color: #195A84 !important;
}

.c-app {
    --primary: #195A84;
    --secondary: #ced2d8;
    --success: #A2C037;
    --info: #39f;
    --warning: #f9b115;
    --danger: #e55353;
    --light: #ebedef;
    --dark: #636f83;
    color: #195A84;
    background-color: #eaf9ff;
    /*background-image: url(./assets/hint.png);*/
    --color: #3c4b64;
    --elevation-base-color: 60, 75, 100;
    /*8ccfff61*/
}

.c-app:not(.c-legacy-theme) .card {
    border: 0;
    box-shadow: 0 9px 9px 0 rgba(var(--elevation-base-color), .14), 0 1px 8px 1px rgba(var(--elevation-base-color), .12), 0 1px 4px 0 rgba(var(--elevation-base-color), .20);
}

.titelbett{
   float:left;
   position: absolute;
}

.ddbett{
   float:right;
}

.cardbett{
    /*height: 45%;*/
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #fff;
    border-color: #d8dbe0;
    border: 0;
    box-shadow: 0 9px 9px 0 rgba(var(--elevation-base-color), 0.14), 0 1px 8px 1px rgba(var(--elevation-base-color), 0.12), 0 1px 4px 0 rgba(var(--elevation-base-color), 0.2);
}

.undertxt{
  font-size:11px;
  word-wrap:break-word;

}

.boxbett{
  font-size:10px;
}

.blockright{
   //display:block;
   display: flex;
   float:right;
}

.formimg{
   width:85%;
   padding-left:50px;
}

.formabstand{
   padding-left:50px;
   width:200px;
   float:left;
}

.formabstandtwo{
   padding-left:50px;
   width:400px;
}

.formabstandtop{
   margin-top:20px;
}

.abstform{
   margin-left:5px;
   border:1px solid #e4e7ea;
}

.abstforml{
   margin-left:10px;
}

.defaultoff{
   display:none;
}

.cbody{
  padding:20px;
}

.hauptwertanzeige{
  font-size:16px;
  color: #6aa842;
}

.msgbox{
  position: absolute;
  border: 0;
  inset: 0px 0px auto auto;
  margin: 0px;
  padding:20px;
  margin-top: 20px;
  border-radius: 4px;
  background:#fff;
  display: block;
  transform: translate3d(0px, 36px, 0px);
  box-shadow: 0 4px 5px 0 rgba(var(--elevation-base-color), .14), 0 1px 10px 0 rgba(var(--elevation-base-color), .12), 0 2px 4px -1px rgba(var(--elevation-base-color), .20);
}

.progress {
  height: 20px;
  width: 100%;
  background-color: #d5d5d5;
  position: relative;
  color: #fff;
}

.progress:before {
  content: attr(data-label);
  font-size: 11px;
  position: absolute;
  text-align: center;
  top: 10px;
  left: 0;
  right: 0;
}

.progress .value {
  background-color: #1976d2;
  display: inline-block;
  height: 100%;
  width: var(--processwidth);
}

.hbody{
  height: 20px;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.calformid{
   display:none;
}

.btn{
   background-color:#1976d2;
   color:#fff;
}

.divleft{
   float:left;
   margin-right:5px;
}

.posiconh4{
      font-size: 1.3125rem;
      margin-top: -4px;
}

.h5color{
  color:#195A84;
}

.h7color{
  color:#195A84;
  margin-left:5px;
}

.h7colorint{
  color:#195A84;
  margin-left:15px;
}

.accordinput{
  width:20px;
  float:right;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    margin-bottom: 15px;
}

.paddingacc{
     margin-bottom: 15px;
     background-color: #fff;
     color: rgba(0, 0, 0, 0.87);
     -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     //box-shadow: 1px 4px 7px #bdccd7;
     box-shadow: 0px 1px 1px #707374;
     transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     border-radius: 4px;
     position: relative;
     -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     overflow-anchor: none;
     border-radius: 0;
}

.accheader{
   width:100%;
}

.iconcolorbacksun{
    color:#555;
}

.spinnerdone{
  display:none;
}

.headerswitch{
   margin-right: 20px;
}

.dashbutton {
    display: inline-block;
    font-weight: 400;
    color: #3c4b64;
    width:100%;
    height:70px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color:#0d86bc;
    color:#fff;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.icondash{
    height:30px;
}

.dash5w{
    width:200px;
}

.row-icon{
  width:170px;
}

.row-two{
  width:170px;
}

.regpw{
  font-size:12px;
  color:#aaa;
  padding-bottom: 10px;
}

.inputmui {
    font: inherit;
    font-size: 10px;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 10px;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.arefmousef{
  cursor:pointer;
  width:25px;
  float:right;
  opacity: 0.5;
}

.info{
  padding:25px;
  width:100%;
}

.questpos{
  float:right;
}

.frmdate{
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    margin-bottom: 50px;
}

@media (min-width: 600px){
  .medialeft {
          display: flex;
  }
}

.dropdownm{
        width: 35px;
        margin-right: -16px;
        margin-top: -43.5px;
        cursor: pointer;
        height: 30px;
    /*width: 40px;
    margin-right: -16px;
    margin-top: -88px;
    cursor: pointer;*/
}

@media (min-width: 992px){
  .col-lg-3 {
      flex: 0 0 50%;
      max-width: 100%;
  }
}

@media (min-width: 1404px){
  .col-lg-3 {
      flex: 0 0 30%;
      max-width: 100%;
  }
}

.einheit{
  font-size:24px;
}

.unterteilung{
  height: 100px;
  width: 100%;
}

.hrst{
    height: 40px;
    border-bottom: 2px solid;
    border-top: 0px solid;
    border-color: #007fb8;
}

.ubueabclass{
    margin-left: 20px;
    position: absolute;
    color: #195A84;
    margin-top: 20px;
}

.inputcui{
        margin-top: -30px;
        float: right;
        padding-right:10px;
}

.c-user-header {
    height: 20px;
    font-size: 1.09375rem;
    text-align: center;
    transition: 0.3s;
    fill: #6a6a6a;
    font-weight:bolder;
}
